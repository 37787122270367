import {
    applyMiddleware,
    combineReducers,
    createStore,
} from "redux";

import { composeWithDevTools } from "@redux-devtools/extension";
import thunk from "redux-thunk";

import app from "@/base/store-common/app/reducer.js";
import device from "@/base/store-common/device/reducer.js";
import monitors from "@/base/store-common/monitors/reducer.js";
import pages from "@/base/store-common/pages/reducer.js";
import vocabulary from "@/base/store-common/vocabulary/reducer.js";
import text2speech from "@/base/store-common/text2speech/reducer.js";
import translation from "@/base/store-common/translation/reducer.js";
import player from "@/base/store-common/player/reducer.js";
import search from "@/base/store-common/search/reducer.js";

import navigation from "./navigation/reducer.js";

import info from "./info/reducer.js";
import user from "./user/reducer.js";
import lms from "./lms/reducer.js";

import notifications from "./notifications/reducer.js";
import tutorials from "./tutorials/reducer.js";
import templates from "./templates/reducer.js";
import popups from "./popups/reducer.js";

import juices from "./juices/reducer.js";
import juiceStories from "./juice-stories/reducer.js";
import juicesVideos from "./juices-videos/reducer.js";
import extraJuices from "./extra-juices/reducer.js";
import polls from "./polls/reducer.js";

import teacher from "./teacher/reducer.js";
import teacherClass from "./teacher-class/reducer.js";
import teacherStudents from "./teacher-students/reducer.js";
import teacherStudentsAchievements from "./teacher-students-achievements/reducer.js";
import teacherStudentsProgress from "./teacher-students-progress/reducer.js";
import teacherStudentsScoreReport from "./teacher-students-score-report/reducer.js";
import teacherStudentsAssignments from "./teacher-students-assignments/reducer.js";

import guardian from "./guardian/reducer.js";
import student from "./student/reducer.js";
import studentTodo from "./student-todo/reducer.js";
import studentAssignments from "./student-assignments/reducer.js";
import studentAchievements from "./student-achievements/reducer.js";
import studentClasses from "./student-classes/reducer.js";
import studentDailyJuices from "./student-daily-juices/reducer.js";

import districtAdmin from "./district-admin/reducer.js";
import districtAdminPopups from "./district-admin-popups/reducer.js";

import uiAdminPopups from "./ui-admin-popups/reducer.js";
import admin from "./admin/reducer.js";

import standards from "./standards/reducer.js";
import assignments from "./assignments/reducer.js";
import geo from "./geo/reducer.js";
import subscription from "./subscription/reducer.js";
import fivesy from "./fivesy/reducer.js";

import uiPopups from "./ui-popups/reducer.js";
import uiStoriesRating from "./ui-stories-rating/reducer.js";


const configureStore = () => {
    const rootReducer = combineReducers({
        app,
        device,
        monitors,
        navigation,

        info,
        user,
        lms,

        text2speech,
        translation,
        player,

        notifications,
        tutorials,
        templates,
        popups,
        pages,
        vocabulary,

        juices,
        juiceStories,
        juicesVideos,
        extraJuices,
        polls,

        teacher,
        teacherClass,
        teacherStudents,
        teacherStudentsAchievements,
        teacherStudentsProgress,
        teacherStudentsScoreReport,
        teacherStudentsAssignments,

        guardian,
        student,
        studentTodo,
        studentAssignments,
        studentAchievements,
        studentClasses,
        studentDailyJuices,

        districtAdmin,
        districtAdminPopups,

        uiAdminPopups,
        admin,

        search,

        standards,
        assignments,
        geo,
        subscription,
        fivesy,

        uiPopups,
        uiStoriesRating,
    });

    const middlewares = [thunk];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);

    const initialState = {};
    return createStore(rootReducer, initialState, composedEnhancers);
};

export default configureStore;

import urls from "@/base/lib/urls.js";

import DailyJuice from "@/base/project/daily-juice.js";
import URLS from "@/base/project/urls.js";


const classParams = (state) => {
    const params = state.navigation.params || {};

    const isDailyJuiceView = DailyJuice.isDailyJuiceView(params.view);
    const isAssignmentsView = DailyJuice.isAssignmentsView(params.view);
    const isScoreReportView = DailyJuice.isScoreReportView(params.view);

    const isProgressView = DailyJuice.isProgressView(params.view)
        || DailyJuice.isProgressView(params.dateFrom)
        || URLS.isClassProgress(state.navigation.location.pathname);

    let view = "";
    let dateFrom = "";
    let dateTo = "";

    let hasDateRange = false;

    if (isDailyJuiceView || isAssignmentsView || isScoreReportView) {
        view = params.view;

        dateFrom = params.dateFrom || "";
        dateTo = params.dateToOrLoadType || "";

        hasDateRange = !!(dateTo && dateFrom);
    } else if (isProgressView) {
        view = "progress";
    }

    const searchParams = {};

    if (isScoreReportView) {
        const searchQuery = urls.parseSearchParams(state.navigation.location.search || "");

        searchParams.standardType = searchQuery?.standardType || "";
        searchParams.standards = urls.getDecodedArrayFromSearchParam(
            searchQuery?.standards || "",
            true,
        );
    }

    return {
        view,

        isDailyJuiceView,
        isAssignmentsView,
        isProgressView,
        isScoreReportView,

        searchParams,

        dateFrom,
        dateTo,
        hasDateRange,
    };
};

const classStudentParams = (state) => {
    const params = state.navigation.params || {};

    const values = {
        district: "",
        school: "",
        department: "",
        classId: "",
    };

    Object.keys(values).forEach((key) => {
        const value = params[key] || "";
        const id = parseInt(value, 10) || "";
        values[key] = id;
    });

    /* --- */

    const isDailyJuiceView = DailyJuice.isDailyJuiceView(params.view);
    const isAssignmentsView = DailyJuice.isAssignmentsView(params.view);
    const isScoreReportView = DailyJuice.isScoreReportView(params.view);

    const isProgressView = DailyJuice.isProgressView(params.view)
        || DailyJuice.isProgressView(params.dateFrom)
        || URLS.isClassProgress(state.navigation.location.pathname)
        || URLS.isClassProgressDistrictAdmin(state.navigation.location.pathname)
        || URLS.isClassProgressAdmin(state.navigation.location.pathname);

    let view = "";
    let dateFrom = "";
    let dateTo = "";

    let hasDateRange = false;

    if (isDailyJuiceView || isAssignmentsView || isScoreReportView) {
        view = params.view;

        dateFrom = params.dateFrom || "";
        dateTo = params.dateToOrLoadType || "";

        hasDateRange = !!(dateTo && dateFrom);
    }

    if (isProgressView) {
        view = "progress";
    }

    return {
        ...values,
        view,

        isDailyJuiceView,
        isAssignmentsView,
        isScoreReportView,
        isProgressView,

        dateFrom,
        dateTo,
        hasDateRange,

        studentId: params.studentId || "",
        studentViewTab: params.studentViewTab || "",
    };
};

const districtAdminClassParams = (state) => {
    const params = state.navigation.params || {};

    /* --- */

    let school = "";

    if (params.school) {
        school = parseInt(params.school, 10) || "";
    }

    /* --- */

    let department = "";

    if (params.department) {
        department = parseInt(params.department, 10) || "";
    }

    /* --- */

    let classId = "";

    if (params.classId) {
        classId = parseInt(params.classId, 10) || "";
    }

    /* --- */

    const isDailyJuiceView = DailyJuice.isDailyJuiceView(params.view);
    const isAssignmentsView = DailyJuice.isAssignmentsView(params.view);
    const isScoreReportView = DailyJuice.isScoreReportView(params.view);
    const isProgressView = DailyJuice.isProgressView(params.view)
        || DailyJuice.isProgressView(params.dateFrom)
        || URLS.isClassProgressDistrictAdmin(state.navigation.location.pathname);


    /* --- */

    let view = "";
    let dateFrom = "";
    let dateTo = "";

    let hasDateRange = false;

    if (isDailyJuiceView || isAssignmentsView || isScoreReportView) {
        view = params.view;

        dateFrom = params.dateFrom || "";
        dateTo = params.dateToOrLoadType || "";

        hasDateRange = !!(dateTo && dateFrom);
    }

    if (isProgressView) {
        view = "progress";
    }

    return {
        school,
        department,
        classId,

        view,

        isDailyJuiceView,
        isAssignmentsView,
        isProgressView,
        isScoreReportView,

        dateFrom,
        dateTo,
        hasDateRange,
    };
};

const adminClassParams = (state) => {
    const params = state.navigation.params || {};

    const values = {
        district: "",
        school: "",
        department: "",
        classId: "",
    };

    Object.keys(values).forEach((key) => {
        const value = params[key] || "";
        const id = parseInt(value, 10) || "";
        values[key] = id;
    });

    /* --- */

    const isDailyJuiceView = DailyJuice.isDailyJuiceView(params.view);
    const isAssignmentsView = DailyJuice.isAssignmentsView(params.view);
    const isScoreReportView = DailyJuice.isScoreReportView(params.view);

    const isProgressView = DailyJuice.isProgressView(params.view)
        || DailyJuice.isProgressView(params.dateFrom)
        || URLS.isClassProgressAdmin(state.navigation.location.pathname);

    /* --- */

    let view = "";
    let dateFrom = "";
    let dateTo = "";

    let hasDateRange = false;

    if (isDailyJuiceView || isAssignmentsView || isScoreReportView) {
        view = params.view;

        dateFrom = params.dateFrom || "";
        dateTo = params.dateToOrLoadType || "";

        hasDateRange = !!(dateTo && dateFrom);
    }

    if (isProgressView) {
        view = "progress";
    }

    return {
        ...values,

        view,

        isDailyJuiceView,
        isAssignmentsView,
        isScoreReportView,
        isProgressView,

        dateFrom,
        dateTo,
        hasDateRange,
    };
};


const adminClassSelectorParams = (state) => {
    const params = state.navigation.params || {};

    const selector = {
        district: "",
        school: "",
        department: "",
        classId: "",
    };

    const keys = Object.keys(selector);

    for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];

        const value = params[key] || "";
        const id = parseInt(value, 10);

        if (!Number.isNaN(id)) {
            selector[key] = id;
        }
    }

    return selector;
};

export default {
    classParams,
    classStudentParams,

    districtAdminClassParams,
    adminClassParams,

    adminClassSelectorParams,
};

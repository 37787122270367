import types from "./types.js";


const setClassStudentsGradesByRangeLoading = (payload) => ({
    type: types.SET_TEACHER_CLASS_STUDENTS_GRADES_BY_RANGE_LOADING,
    payload,
});

const setClassStudentsGradesByRange = (payload) => ({
    type: types.SET_TEACHER_CLASS_STUDENTS_GRADES_BY_RANGE,
    payload,
});


export default {
    setClassStudentsGradesByRangeLoading,
    setClassStudentsGradesByRange,
};

import * as globalTypes from "@/base/store-common/types.js";
import types from "./types.js";


const initialState = {
    classStudentsGradesByRange: {}
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_TEACHER_CLASS_STUDENTS_GRADES_BY_RANGE_LOADING: {
            const { classId, dateFrom, dateTo } = action.payload;

            const range = `${dateFrom}-${dateTo}`;

            const prevByClassId = state.classStudentsGradesByRange[classId] || {};
            const prevByRange = prevByClassId[range] || {};

            return {
                ...state,
                classStudentsGradesByRange: {
                    ...state.classStudentsGradesByRange,
                    [classId]: {
                        ...prevByClassId,
                        [range]: {
                            ...prevByRange,
                            error: "",
                            isLoading: true,
                        },
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASS_STUDENTS_GRADES_BY_RANGE: {
            const {
                classId,
                error,
                grades,
                dateFrom,
                dateTo,
            } = action.payload;

            const range = `${dateFrom}-${dateTo}`;

            const prevByClassId = state.classStudentsGradesByRange[classId] || {};
            const prevByRange = prevByClassId[range] || {};

            return {
                ...state,
                classStudentsGradesByRange: {
                    ...state.classStudentsGradesByRange,
                    [classId]: {
                        ...prevByClassId,
                        [range]: {
                            ...prevByRange,
                            error,
                            grades,
                            isLoaded: true,
                            isLoading: false,
                        },
                    },
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
